import { createApp } from 'vue'
import App from './App.vue'
import './assets/styles/main.scss'


declare global {
    interface Window {
        dataLayer: Record<string, any>[];
    }
}


createApp(App).mount('#app')
